import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
const vue = new Vue()

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'userIndex',
    redirect: { path: '/index' },
    component: () => import('@/views/userIndex/index.vue'),
    meta: {
      title: '首页',
    },
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/userIndex/index/index.vue'),
        meta: {
          title: '首页',
          cache: true,
        },
      },
      {
        path: '/groupPurchase',
        name: 'groupPurchase',
        component: () => import('@/views/userIndex/groupPurchase/index.vue'),
        meta: {
          title: '美食团购',
          cache: true,
        },
      },
      {
        path: '/buyMedicine',
        name: 'buyMedicine',
        component: () => import('@/views/userIndex/buyMedicine/index.vue'),
        meta: {
          title: '药品',
          cache: true,
        },
      },
      {
        path: '/job',
        name: 'job',
        component: () => import('@/views/userIndex/job/index.vue'),
        meta: {
          title: '招聘',
          cache: true,
        },
      },
      {
        path: '/jobDetail',
        name: 'jobDetail',
        component: () => import('@/views/userIndex/job/jobDetail.vue'),
        meta: {
          title: '招聘详情',
          cache: false,
        },
      },
      {
        path: '/checkin',
        name: 'checkin',
        component: () => import('@/views/userIndex/checkin/index.vue'),
        meta: {
          title: '商家入驻',
          cache: true,
        },
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/userIndex/about/index.vue'),
        meta: {
          title: '关于我们',
          cache: true,
        },
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/userIndex/order/index.vue'),
        meta: {
          title: '我的订单',
          cache: false,
        },
      },
      {
        path: '/goodsDetail',
        name: 'goodsDetail',
        component: () => import('@/views/userIndex/goodsDetail/index.vue'),
        meta: {
          title: '商品详情',
          cache: false,
        },
      },
      {
        path: '/userShopsDetail',
        name: 'userShopsDetail',
        component: () => import('@/views/userIndex/shopsDetail/index.vue'),
        meta: {
          title: '店铺详情',
          cache: false,
        },
      },
      {
        path: '/im',
        name: 'userIm',
        component: () => import('@/views/userIndex/im/index.vue'),
        meta: {
          title: '消息中心',
          cache: false,
        },
      },
      // {
      //   path: '*',
      //   name: 'notFind',
      //   component: () => import('@/views/userIndex/notFind/index.vue'),
      //   meta: {
      //     title: '404'
      //   }
      // }
    ],
  },
  {
    path: '/manage',
    name: 'manage',
    redirect: { path: '/manage/orderForm' },
    component: () => import('@/views/manage/index.vue'),
    meta: {
      title: '后台管理',
    },
    children: [
      {
        path: '/manage/orderForm',
        name: 'orderForm',
        component: () => import('@/views/manage/orderForm/index.vue'),
        meta: {
          title: '订单管理',
          isMenu: true,
          userType: ['admin', 'userAdmin'],
          cache: true,
        },
      },
      {
        path: '/manage/merchant',
        name: 'merchant',
        component: () => import('@/views/manage/merchant/index.vue'),
        meta: {
          title: '商家管理',
          isMenu: true,
          userType: ['admin'],
          cache: true,
        },
      },
      {
        path: '/manage/user',
        name: 'user',
        component: () => import('@/views/manage/user/index.vue'),
        meta: {
          title: '用户管理',
          isMenu: true,
          userType: ['admin'],
          cache: true,
        },
      },
      {
        path: '/manage/commodity',
        name: 'commodity',
        component: () => import('@/views/manage/commodity/index.vue'),
        meta: {
          title: '商品管理',
          isMenu: true,
          userType: ['admin', 'userAdmin'],
          cache: true,
        },
      },
      {
        path: '/manage/menuCategory',
        name: 'menuCategory',
        component: () => import('@/views/manage/menuCategory/index.vue'),
        meta: {
          title: '商品分类',
          isMenu: true,
          userType: ['admin'],
          cache: true,
        },
      },
      {
        path: '/manage/audit',
        name: 'audit',
        component: () => import('@/views/manage/audit/index.vue'),
        meta: {
          title: '商家审核',
          isMenu: true,
          userType: ['admin'],
          cache: true,
        },
      },
      {
        path: '/manage/recruitment',
        name: 'recruitment',
        component: () => import('@/views/manage/recruitment/index.vue'),
        meta: {
          title: '招聘管理',
          isMenu: true,
          userType: ['admin', 'userAdmin'],
          cache: true,
        },
      },
      {
        path: '/manage/im',
        name: 'adminIm',
        component: () => import('@/views/manage/im/index.vue'),
        meta: {
          title: '消息中心',
          isMenu: true,
          userType: ['userAdmin'],
          cache: true,
        },
      },
      {
        path: '/manage/shopsDetail',
        name: 'shopsDetail',
        component: () => import('@/views/manage/shopsDetail/index.vue'),
        meta: {
          title: '店铺详情',
          isMenu: false,
          userType: ['admin', 'userAdmin'],
          cache: false,
        },
      },
      {
        path: '/manage/orderCancellation',
        name: 'orderCancellation',
        component: () => import('@/views/manage/orderCancellation/index.vue'),
        meta: {
          title: '订单核销',
          isMenu: true,
          userType: ['userAdmin'],
          cache: true,
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, form, next) => {
  // 刷新时移除部分信息，重新获取最新用户信息，避免重新登陆
  // 拦截未登录强制进入管理页面 开始
  let adminToken = localStorage.getItem('adminToken')
  let isManage = to.path.indexOf('/manage/') !== -1
  if (isManage && !adminToken) {
    next({ path: '/' })
  }
  // if(isManage) {
  //   if(store.state.adminInfo.tenantId === 0){
  //     if(!to.meta.userType.includes('admin')){
  //       next({ path: '/notFind'})
  //     }
  //   } else {
  //     if(!to.meta.userType.includes('userAdmin')){
  //       next({ path: '/notFind'})
  //     }
  //   }
  // }

  store.commit('uploadAdminInfo')
  store.commit('uploadUserInfo')
  // 拦截未登录强制进入管理页面 结束
  store.commit('setRouterHistory', to)
  next()
})

export default router
